.viewToggleButton {
  /* display: flex; */
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.viewToggleButtonRight {
  composes: viewToggleButton;
  /* transform: rotate(180deg); */

}
