.touchMenuHeader {
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--color-a);
  font-family: var(--font-family-a);
  font-size: 1rem;
  font-weight: 500;
  padding: 0.75rem 1rem;
  background-color: rgba(26, 52, 100, .08);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.webMenuHeader {
  composes: touchMenuHeader;
  justify-content: space-between;
  width: 70%;
  border-style: solid;
  color: white;
  border: 1px solid black;
  border-radius: 3px 3px 0 0;
  font-size: .6rem;
  font-style: italic;
  text-transform: capitalize;
  margin-top: 5px;
}

.openArrow {
  display: flex;
  width: fit-content;
  height: fit-content;
  justify-content: flex-end;
  transform: rotate(0deg);
}

.closedArrow {
  composes: openArrow;
  transform: rotate(270deg);
}

