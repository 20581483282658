.selectionsMenu {
  box-shadow: -3px -3px 5px 0px #e3e3e3;
  width: calc(100% - 5px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  border-right: 1px solid black;
  border-left: 1px solid black;
  margin-top: 5px;
  margin-left: 5px;
  /* padding-right: 5px; */
}

.closedSelectionsMenu {
  composes: selectionsMenu;
  transform: rotate(0deg);
  border: none;
}

.touchCardContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* border-left: 1px solid #000;
  border-right: 1px solid #000; */
  overflow-y: auto;
}

.touchCardContainer::-webkit-scrollbar {
  width: 20px;
}

.touchCardContainer::-webkit-scrollbar-track {
  background: var(--color-a-light);
  border-radius: 10px;
}

.touchCardContainer::-webkit-scrollbar-thumb {
  background: var(--color-a);
  border-radius: 10px;
}
  
/* .touchCardContainer::-webkit-scrollbar {
  width: 25px;
}

.touchCardContainer::-webkit-scrollbar-track {
  background: rgb(196, 196, 196);
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-radius: 3px;
}

.touchCardContainer::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 3px;
  border: 1px solid rgb(141, 141, 141);
} */

.closedTouchCardContainer {
  composes: touchCardContainer;
  border: none;
}

.webCardContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  overflow-y: auto;
}
  
.webCardContainer::-webkit-scrollbar {
  width: 8px;
}

.webCardContainer::-webkit-scrollbar-track {
  background: rgb(196, 196, 196);
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-radius: 3px;
}

.webCardContainer::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 3px;
  border: 1px solid rgb(141, 141, 141);
}

.closedWebCardContainer {
  composes: webCardContainer;
  border: none;
}