.kovaStage {
  position: absolute;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
}

.kovaElevation {
  height: 100%;
}

.elevationImgContainer {
  height: 100%;
  width: 100%;
}

.kovaElevationImg {
  width: auto;
  height: 100%;
}