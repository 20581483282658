.viewToggle {
  background-color: rgba(88, 83, 71, .06);
  height: 100%;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  padding: 0 2rem;
  position: relative;
  z-index: 10000;
}

.viewName {
  color: var(--color-a);
  display: flex;
  /* flex: 1 0 40%; */
  font-size: 0.875rem;
  font-weight: 600;
  flex-grow: 1;
  justify-content: center;
}

.viewToggleButtonContainer {
  display: unset;
  height: 50px;
  width: 50px;
}

/* @media screen and (min-width: 800px) {
  .viewToggle {
    font-size: 1.4rem;
  }
  .viewName {
    flex: 0 0 40%;
  }
}

@media screen and (min-width: 1200px) {
  .viewToggle {
    font-size: 1.5rem;
  }
  .viewName {
    flex: 0 0 20%;
  }
} */
