.selectionItem {
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-height: 2.2rem;
  width: 100%;
  color: var(--color-a);
  font-family: var(--font-family-a);
  font-size: .75rem;
  font-weight: 300;
  background-color: #fff;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

/* .selectionItem:nth-child(even) {
  background-color: rgb(245, 245, 245);
} */

.optionPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #086EBB;
  min-height: 1.8rem;
  margin: 0 2px;
  color: white;
  flex: 0 0 15%;
  border-radius: 3px;
}

.optionName {
  flex: 1 0 50%;
  padding-left: 2%;
}

.disabled {
  composes: selectionItem;
  color: rgb(175, 175, 175);
  pointer-events: none;
}

.icons {
  margin-right: 5px;
}

.switchInput {
  display: none;
}

.buttonContainer {
  display: inline-block;
  flex-shrink: 0;
  height: 30px;
  position: relative;
  width: 30px;
  margin-right: 5px;
}

.slider {
  border: 3px solid var(--color-a);
  bottom: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.slider::before {
  bottom: 4px;
  content: "";
  height: 14px;
  left: 5px;
  position: absolute;
  width: 14px;
}

input:checked + .slider { 
  border: 3px solid var(--color-a);
}

input:checked + .slider::before {
  background: var(--color-b);
  border-radius: 50%;
}

.slider {
  border-radius: 50%;
}

.SlideItem .switch .slider::before {
  border-radius: 50%;
}
