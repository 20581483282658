.touchscreenDashboard {
  display: grid;
  grid-column-gap: 2px;
  grid-row-gap: 10px;
  grid-template-columns: 5fr 370px;
  grid-template-rows: 750px 100px;
  grid-template-areas: 
    "stage       rightMenu"
    "viewToggle  buttonPanel";
  padding: 25px 50px 25px 50px;
  /* margin-left: 375px; */
}

.viewToggleContainer {
  display: flex;
  justify-content: center;
  grid-area: viewToggle;
}

.stageContainer {
  grid-area: stage;
  min-width: 800px;
  text-align: center;
  position: relative;
}

.modelNameContainer {
  grid-area: model;
}

.rightMenuContainer {
  grid-area: rightMenu;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  overflow-y: scroll;
  width: 100%;
}

.touchscreenRightMenuContainer {
  composes: rightMenuContainer;
  border: 1px solid black;
}

.leftMenuContainer {
  grid-area: leftMenu;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.rightMenuTabContainer {
  display: flex;
  width: 100%;
  flex: 0 0 40px;
  position: relative;
  z-index: 10000;
}

.optionListContainer {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  position: relative;
  z-index: 10000;
}

.rightSideMenuButtonContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-self: end;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.clearSideMenuButtonContainer {
  composes: rightSideMenuButtonContainer;
  flex-grow: 1;
}

.resetSideMenuButtonContainer {
  composes: rightSideMenuButtonContainer;
}

.rightMenuContainer::-webkit-scrollbar {
  width: 0px;
}

.buttonPanelContainer {
  grid-area: buttonPanel;
  width: 100%;
  /* border: 2px solid blue; */
}

.unselectable { 
  -webkit-user-select: none; 
  -webkit-touch-callout: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none;    
} 

/* @media screen and (min-width: 768px) {
  .webDashboard {
    display: grid;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    grid-template-columns: 250px 4fr;
    grid-template-rows: 50px 59px 350px auto 1fr;
    grid-template-areas: 
      "leftMenu   model"
      "leftMenu   viewToggle"
      "leftMenu   stage"
      "leftMenu   stage"
      "leftMenu   footer";
    padding: 5px;
  }

  .footerContainer {
    grid-area: footer;   
    display: flex;
    justify-content: flex-end;
  }

  .leftMenuContainer {
    grid-area: leftMenu;
  }

  .modelNameContainer {
    grid-area: model;
  }

  .viewToggleContainer {
    display: unset;
    grid-area: viewToggle;
    max-height: 25px;
  }

  .footerContainer {
    grid-area: footer;   
    display: flex;
    justify-content: flex-end;
  }

  .mobileMenuContainer {
    display: none;
  }
} */

@media screen and (min-width: 768px) {
  .webDashboard {
    display: grid;
    grid-column-gap: 2px;
    grid-row-gap: 10px;
    grid-template-columns: 200px 5fr 200px;
    grid-template-rows: 30px 25px 5fr 80px;
    grid-template-areas: 
      "model   model  model"
      "viewToggle   viewToggle  viewToggle"
      "leftMenu        stage       rightMenu"
      "leftMenu        stage       rightMenu"
      "leftMenu        stage       rightMenu"
      "footer       footer      footer";
    width: 100%;
    height: 100%;
    padding: 0 50px 25px 50px;
  }
  
  .footerContainer {
    grid-area: footer;
  }
}

@media screen and (min-width: 1200px) {
  .webDashboard {
    display: grid;
    grid-column-gap: 2px;
    grid-row-gap: 10px;
    grid-template-columns: 225px 5fr 250px;
    grid-template-rows: 50px 40px 109px 350px 300px 80px;
    grid-template-areas: 
      "model   model  model"
      "viewToggle   viewToggle  viewToggle"
      "leftMenu        stage       rightMenu"
      "leftMenu        stage       rightMenu"
      "leftMenu        stage       rightMenu"
      "footer       footer      footer";
    width: 100%;
    height: 100%;
    padding: 0 50px 25px 50px;
  }
  
  .footerContainer {
    grid-area: footer;
  }

  /* .rightMenuContainer {
    grid-area: rightMenu;
  } */
}
